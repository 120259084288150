import React from 'react'

import { Layout } from '../components/common'

const NotFoundPage = ({ intl }) => (
  <Layout>
    <h1>Not found</h1>
  </Layout>
)

export default NotFoundPage
